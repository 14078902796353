import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from './../Grid'
import Logo from '../Logo'
import { css } from 'emotion'
import styled from 'react-emotion'

import LanguageDropdown from '../../components/LanguageDropdown'
import supportedLanguages from '../../config/supportedLanguages'
import { CONST } from '../../utils'
import { H1, SecondaryText } from '../Typography'
import IntlContext from '../IntlContext'
import { useLocaleState } from '../../utils/LanguageProvider'
import LinkBetweenText from '../LinkBetweenText'
import Icon from '../Icon'

export const CONTENT_WIDTH = 460
export const TOP_MARGIN = 60
const CONTENT_PADDING = 32

const ContentContainer = styled(Box, {
  label: 'ContentContainer'
})`
  ${({ disableFormFocus }) =>
    `
      position: relative;
      background: ${disableFormFocus ? 'transparent' : 'white'};
      border-radius: 14px;
      box-shadow: ${
        disableFormFocus
          ? 'none'
          : `0 0 5px 0 rgba(18,52,77,0.16), 0 12px 36px 0 rgba(18,52,77,0.04);`
      };
      max-width: ${CONTENT_WIDTH}px;
    `}
`

const CenterAlignStyles = css`
  text-align: center;
`

const FooterStyle = css`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 16px;
  max-width: ${CONTENT_WIDTH}px;
`

const AnchorStyles = css`
  font-size: 12px;
  font-weight: 500;
  margin: 0 2px;
`

const policyLinksStyleWithoutLanguage = css`
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  padding: 0px 20px;
`

const BoxShadowContainerStyle = css`
  max-width: ${CONTENT_WIDTH}px;
  margin: 0px auto 16px auto;
`

const BoxShadowStyles = css`
  height: 0;
  background: transparent;
  border: none;
  margin: 0 ${CONTENT_PADDING}px;
  box-shadow: 0 1px 15px 3px #12344d4f;
`

const Height100 = { height: '100vh' }

const OneColumnContentSection = React.memo(
  ({
    orgLogo,
    showPolicies,
    buttonNamePath,
    showLanguageDropdown,
    disableFormFocus,
    entryPointData,
    errorText,
    mainHeadingText,
    CustomLogoName,
    logoRedirectionPath = null,
    children
  }) => {
    const [localeConfig, setLocale] = useLocaleState()
    return (
      <IntlContext.Consumer>
        {({ toIntlString }) => (
          <Flex flexDirection="column" width={1} role="main">
            {orgLogo ? (
              <Box width={1} className={CenterAlignStyles} mt={TOP_MARGIN} mb={4}>
                {CustomLogoName ? (
                  <>
                    {logoRedirectionPath ? (
                      <a href={logoRedirectionPath}>
                        <Icon width="auto" name={CustomLogoName} />
                      </a>
                    ) : (
                      <div>
                        <Icon width="auto" name={CustomLogoName} />
                      </div>
                    )}
                  </>
                ) : (
                  <Logo size="large" entryPointData={entryPointData} />
                )}
                {!!mainHeadingText && (
                  <Box mt="20px">
                    <H1 overrideTag={'h3'}>{toIntlString(mainHeadingText)}</H1>
                  </Box>
                )}
              </Box>
            ) : null}
            <Box mt={!orgLogo ? TOP_MARGIN : 0} width={1} px="20px">
              {errorText && (
                <Box p={2} mb={2} width={CONTENT_WIDTH} mx="auto">
                  <SecondaryText textWeight="bold" textType="error">
                    {errorText}
                  </SecondaryText>
                </Box>
              )}
              <ContentContainer mx="auto" p={CONTENT_PADDING} disableFormFocus={disableFormFocus}>
                {children}
              </ContentContainer>
              {!disableFormFocus && (
                <Box className={BoxShadowContainerStyle}>
                  <Box className={BoxShadowStyles}></Box>
                </Box>
              )}
            </Box>
            {!disableFormFocus ? (
              <Box className={FooterStyle} width={1}>
                {showPolicies ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    className={policyLinksStyleWithoutLanguage}>
                    <SecondaryText>
                      {toIntlString('common_login.by_clicking', {
                        button_name: toIntlString(buttonNamePath)
                      })}
                      <LinkBetweenText
                        translationKey={'common_login.acceptance'}
                        placeHolderReplacementObject={{
                          _terms: {
                            textKey: 'common_login._terms',
                            link: CONST.FRESHWORKS_TERMS_CONDITIONS,
                            target: '_blank'
                          },
                          _privacy: {
                            textKey: 'common_login._privacy',
                            link: CONST.FRESHWORKS_PRIVACY,
                            target: '_blank'
                          }
                        }}
                        className={AnchorStyles}
                        data-testid="terms_and_privacy_link"
                      />
                    </SecondaryText>
                  </Flex>
                ) : null}
                {showLanguageDropdown && (
                  <Box mx="auto">
                    <LanguageDropdown
                      supportedLanguages={supportedLanguages}
                      selectedLanguage={localeConfig.locale}
                      onLanguageChange={(languageCode) => {
                        setLocale(languageCode)
                      }}
                      labelId={toIntlString('my_profile.button.language_dropdown')}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
          </Flex>
        )}
      </IntlContext.Consumer>
    )
  }
)

OneColumnContentSection.propTypes = {
  /**
   * Bool to switch displaying the Org Logo
   */
  orgLogo: PropTypes.bool,
  /**
   * Bool to switch displaying the `Terms` and `Privacy` links
   */
  showPolicies: PropTypes.bool,
  /**
   * intl path to identify the button name
   */
  buttonName: PropTypes.string,
  /**
   * Bool to disable form highlighting with white background
   */
  disableFormFocus: PropTypes.bool,
  /*
   * Structure {id: <entryPointId>, slug: <entryPointSlug>} in context of login page to load EntryPoint logo
   */
  entryPointData: PropTypes.object,
  /*
   * String that is shown as error between the org logo and content box
   */
  errorText: PropTypes.string
}

OneColumnContentSection.defaultProps = {
  orgLogo: true,
  showPolicies: false,
  disableFormFocus: false,
  showLanguageDropdown: true,
  errorText: '',
  mainHeadingText: '',
  buttonName: ''
}

class OneColumnContentBox extends Component {
  static OneColumnContentSection = OneColumnContentSection

  render() {
    return (
      <Box width={1} style={Height100} {...this.props}>
        {this.props.children}
      </Box>
    )
  }
}

export default OneColumnContentBox
