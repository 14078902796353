// any new route introduced in the app should be a part of this config file
// `routePath`: route that will render a given component
// `translationKey`: key that points to the given page related translations within `en-us.json`
// it is expected that the translation object of a particular page should definitely
// contain a property `page_title`, which will contain the page's title

import { CONST } from '.'

const ACTIVITY_CONSTANTS = {
  GET_LOGIN_METHODS: 'getAllowedLoginMethods',
  GET_CURRENT_USER: 'getCurrentUserDetails'
}
const { GET_CURRENT_USER, GET_LOGIN_METHODS } = ACTIVITY_CONSTANTS

const AUTH_DETAILS_IGNORE_APIS = [GET_LOGIN_METHODS]
const ROUTE_CONFIG = [
  {
    routePath: CONST.LOGIN_PATHNAME,
    translationKey: 'common_login'
  },
  {
    routePath: CONST.RE_AUTH_PATHNAME,
    translationKey: 'common_login',
    mandatoryMetaAPIToIgnore: [GET_CURRENT_USER]
  },
  {
    routePath: '/session-exists',
    translationKey: 'common_login'
  },
  {
    routePath: '/login/update-password',
    translationKey: 'common_login.reset_password'
  },

  {
    routePath: '/forgot-password',
    translationKey: 'common_login.forgot_password',
    mandatoryMetaAPIToIgnore: [GET_CURRENT_USER]
  },
  {
    routePath: '/magiclink/request',
    translationKey: 'common_login.magiclink_login'
  },
  {
    routePath: '/reset-password/:hash',
    translationKey: 'common_login.reset_password',
    mandatoryMetaAPIToIgnore: [...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/invite/:hash',
    translationKey: 'user_activation',
    mandatoryMetaAPIToIgnore: [...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/magiclink/:hash/login',
    translationKey: 'common_login.magiclink_login',
    mandatoryMetaAPIToIgnore: [...AUTH_DETAILS_IGNORE_APIS]
  },

  {
    routePath: '/mfa/setup',
    translationKey: 'my_profile.multi_factor_authentication',
    mandatoryMetaAPIToIgnore: [...AUTH_DETAILS_IGNORE_APIS]
  },

  {
    routePath: '/set-password/:hash',
    translationKey: 'set_password',
    mandatoryMetaAPIToIgnore: [...AUTH_DETAILS_IGNORE_APIS]
  },
  {
    routePath: '/unlock/:hash',
    translationKey: 'unlock'
  },

  {
    routePath: '/login/mfa/auth-code',
    translationKey: 'my_profile.multi_factor_authentication.authenticator_code_page'
  },
  {
    routePath: '/login/mfa/recovery-code',
    translationKey: 'my_profile.multi_factor_authentication.recovery_code_page'
  }
]

export default ROUTE_CONFIG
export { AUTH_DETAILS_IGNORE_APIS, ACTIVITY_CONSTANTS }
