import React from 'react'
import styled, { css, cx } from 'react-emotion'
import { styledWithRTL } from './../../utils/styles'

const PrimaryTextColor = ({ theme }) =>
  css`
    color: ${theme.colors.text.primary};
  `

const SecondaryTextColor = ({ theme }) =>
  css`
    color: ${theme.colors.text.secondary};
  `

const LinkTextColor = ({ theme }) =>
  css`
    color: ${theme.colors.link};
  `

/**
 *  This function is introduced for the purpose of accessability
 * A page Must have one Heading Tag
 * DOM order of headings btw Parent and Child must be maintained
 * Ref -> https://www.w3.org/WAI/tutorials/page-structure/headings/
 */
const getHeadingStyle = (tag) => {
  if (!tag) {
    return ''
  }
  return ({ theme, normal, overrideTag = tag }) =>
    cx(
      css({
        ...theme.typography.headings[overrideTag],
        fontWeight: normal ? 400 : 600
      }),
      PrimaryTextColor({ theme })
    )
}

const H1 = styled('h1', { label: 'H1' })`
  ${getHeadingStyle('h1')};
`

const H2 = styled('h2', { label: 'H2' })`
  ${getHeadingStyle('h2')};
`

const H3 = styled('h3', { label: 'H3' })`
  ${getHeadingStyle('h3')};
`

const H4 = styled('h4', { label: 'H4' })`
  ${getHeadingStyle('h4')};
`

const H5 = styled('h5', { label: 'H5' })`
  ${getHeadingStyle('h5')};
`

const HR = styled('hr', { label: 'hr' })`
  ${({ theme: { palette } }) => css({ border: `0.5px solid ${palette.s[50]}` })};
`

const PrimaryText = styledWithRTL('p', { label: 'PrimaryText' })`
  ${({
    theme: {
      typography,
      colors: { text }
    },
    textType,
    textWeight,
    inline
  }) => {
    return cx(
      css(typography.body.regular),
      css({ color: textType ? text[textType] : text.primary }),
      css(textWeight ? typography.text[textWeight] : typography.text.regular),
      css({ display: inline ? 'inline' : 'block' })
    )
  }}
`

const SecondaryText = styled('span', { label: 'SecondaryText' })`
  ${({
    theme: {
      typography,
      colors: { text }
    },
    textType,
    textWeight
  }) => {
    return cx(
      css(typography.body.secondary),
      css({ color: textType ? text[textType] : text.primary }),
      css(textWeight ? typography.text[textWeight] : typography.text.regular)
    )
  }}
`

const SecondaryText12 = styled('label', { label: 'SecondaryText12' })`
  ${PrimaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.secondary12px)};
`

const SecondaryText12Light = styled('span', {
  label: 'SecondaryText12Light'
})`
  ${SecondaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.secondary12px)};
`

const SecondaryText12Bold = styled('span', {
  label: 'SecondaryText12Bold'
})`
  ${PrimaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.secondary12pxBold)};
`

const SecondaryText10Bold = styled('span', {
  label: 'SecondaryText10Bold'
})`
  ${PrimaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.secondary10pxBold)};
`

const SecondaryText9 = styled('span', {
  label: 'SecondaryText9'
})`
  ${PrimaryTextColor};
  ${({
    theme: {
      typography,
      colors: { text }
    },
    textType
  }) => {
    return cx(
      css(typography.body.SecondaryText9px),
      css({ color: textType ? text[textType] : text.primary })
    )
  }}
`

const Label = styled('span', { label: 'Label' })`
  ${SecondaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.label)};
`

const TagText = styled('span', { label: 'TagText' })`
  ${SecondaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.tagText)};
`

const DropDownText = styled('div', { label: 'DropDownText' })`
  ${PrimaryTextColor};
  ${({ theme: { typography } }) => css(typography.body.dropdown)};
`

const Link = styled('span', { label: 'Link' })`
  ${LinkTextColor};
  ${({ theme: { typography } }) => css(typography.body.regular)};
  font-weight: 600;
  cursor: pointer;
`

const LinkText = styled('span', { label: 'LinkText' })`
  ${LinkTextColor};
  ${({ secondary, textWeight, theme: { typography } }) => {
    return cx(
      css(secondary && typography.body.secondary),
      css(textWeight && typography.text[textWeight])
    )
  }}
`

const StyledAnchor = styled('a', { label: 'Anchor' })`
  ${LinkTextColor};
  ${({ theme: { typography }, light, fontSize }) => {
    const anchorTypography = typography.body.anchor
    const anchorFontSize = anchorTypography.fontSize
    return cx(
      css(light ? anchorTypography['light'] : anchorTypography['bold']),
      css({
        fontSize:
          fontSize === 'small'
            ? anchorFontSize.small
            : fontSize === 'large'
            ? anchorFontSize.large
            : anchorFontSize.medium
      })
    )
  }};
`

const Anchor = ({ children, ...props }) => {
  return (
    <StyledAnchor {...props} rel="noopener noreferrer">
      {children}
    </StyledAnchor>
  )
}

const Announce = styled('h1', { label: 'Announce' })`
  ${({ theme: { typography } }) => css(typography.body.regular)};
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  overflow-wrap: break-word;
`

const Bold = styled('span')`
  font-weight: 600;
`

const Semibold = styled('span')`
  font-weight: 500;
`

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  HR,
  PrimaryText,
  SecondaryText,
  SecondaryText12,
  SecondaryText12Light,
  SecondaryText12Bold,
  SecondaryText10Bold,
  SecondaryText9,
  Link,
  LinkText,
  DropDownText,
  Label,
  Announce,
  Bold,
  Semibold,
  Anchor,
  TagText
}
