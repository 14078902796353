import { injectGlobal } from 'emotion'

injectGlobal`
  body {
    font-family: -apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
    overflow: auto;
    margin: 0px;
    padding: 0px;
    background: #ebeff3;
    ul, li, p {
      margin: 0px;
      padding: 0px;
    }
  
    a, a:hover {
      text-decoration: none;
    }

    input::placeholder {
      color: #92A2B1;
      font-weight: normal;
    }

    button {
      font-family: -apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
    }

    textarea {
      font-family: -apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
    }
  }
`
