import React, { Component, lazy, Suspense } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import WindowTitle from './components/WindowTitle/WindowTitle'
import TopErrorBoundary from './components/TopErrorBoundary'
import { AppContainer } from './components/Layout'
import UserSession from './components/UserSession'
import PageNotFound from './components/Errors/PageNotFound'
import { CONST, getQueryParams, updateDocumentDir, analyticsUtils } from './utils'
import retry from './utils/promiseRetry'
import ConnectivityChecker from './components/ConnectivityChecker'
import AppDataProvider from './components/AppDataProvider'
// Further Enhancement: Move to a separate pages folder and use lazy loading
import SessionExists from './pages/MagicLinkLogin/SessionExists'
import './App.css'
import HeadManager from './HeadManager'

const Login = lazy(() => retry(() => import('./pages/Login')))
const ResetPassword = lazy(() => retry(() => import('./pages/ResetPassword')))
const SetPassword = lazy(() => retry(() => import('./pages/SetPassword')))
const ForgotPassword = lazy(() => retry(() => import('./pages/ForgotPassword')))
const RequestMagicLink = lazy(() => retry(() => import('./pages/RequestMagicLink')))
const Unlock = lazy(() => retry(() => import('./pages/Unlock')))
const ActivateUser = lazy(() => retry(() => import('./pages/ActivateUser')))
const MagicLinkLogin = lazy(() => retry(() => import('./pages/MagicLinkLogin')))
const ConfigureMFA = lazy(() => retry(() => import('./pages/ConfigureMFA')))
const MFAValidation = lazy(() => retry(() => import('./pages/Login/MFAValidation')))
const UpdatePassword = lazy(() => retry(() => import('./pages/Login/UpdatePassword')))
const EmailLogin = lazy(() => retry(() => import('./pages/EmailLogin')))
const DomainLogin = lazy(() => retry(() => import('./pages/DomainLogin')))
const ForgotDomain = lazy(() => retry(() => import('./pages/ForgotDomain')))

const SCREEN_NAMES = analyticsUtils.SCREENS

function getComponent(Component, props = {}, withSuspense = true) {
  return function () {
    if (withSuspense) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Component {...props} />
        </Suspense>
      )
    }
    return <Component {...props} />
  }
}

const AnalyticsWrappedComponents = {
  ActivateUser: getComponent(ActivateUser),
  ConfigureMFA: getComponent(ConfigureMFA),
  ForgotPassword: getComponent(ForgotPassword),
  Login: getComponent(Login),
  MagicLinkLogin: getComponent(MagicLinkLogin),
  MFAValidation: getComponent(MFAValidation),
  RequestMagicLink: getComponent(RequestMagicLink),
  ResetPassword: getComponent(ResetPassword),
  SetPassword: getComponent(SetPassword, {}, false),
  UpdatePassword: getComponent(UpdatePassword),
  Unlock: getComponent(Unlock),
  SessionExists: getComponent(SessionExists)
}

const GLOBAL_UI_ROUTES = ['/email-login', '/domain-login', '/forgot-domain']

export const isGlobalUI = GLOBAL_UI_ROUTES.some((route) => window.location.pathname.includes(route))

class App extends Component {
  componentDidMount() {
    updateDocumentDir()
  }

  render() {
    return (
      <AppContainer>
        <ConnectivityChecker />
        <TopErrorBoundary>
          <AppDataProvider renderWithoutContext={isGlobalUI}>
            <WindowTitle isGlobalUI={isGlobalUI} />
            <HeadManager isGlobalUI={isGlobalUI} />
            <Switch>
              {/*
                  If a user loads the *root* route, the redirection url
                  depends on the presence of user session.
                  If it is present we need to load `/` (dashboard)
                  Otherwise we need to load `/login`
                  However the `/` route redirects to `/login` if the user
                  session does not exist. So, the absence of user session will be
                  handled by the `/` (dashboard) route.
                  */}
              <Route
                exact
                path="/login"
                render={(props) =>
                  analyticsUtils.getAnalyticsWrapper(
                    SCREEN_NAMES.LOGIN,
                    AnalyticsWrappedComponents.Login,
                    props
                  )
                }
              />

              <Route
                exact
                path={CONST.RE_AUTH_PATHNAME}
                render={(props) =>
                  analyticsUtils.getAnalyticsWrapper(
                    SCREEN_NAMES.LOGIN,
                    AnalyticsWrappedComponents.Login,
                    props
                  )
                }
              />

              <Route
                path="/login/mfa"
                render={(props) => {
                  const {
                    location: { state = {}, search },
                    history
                  } = props
                  if (!state[CONST.MFA.FROM_LOGIN]) {
                    history.push({ pathname: CONST.LOGIN_PATHNAME, search, state: {} })
                    return null
                  } else {
                    return analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_MFA,
                      AnalyticsWrappedComponents.MFAValidation,
                      props
                    )
                  }
                }}
              />

              <Route
                exact
                path="/mfa/setup"
                render={(props) => {
                  const {
                    location: { state = {}, search }
                  } = props
                  if (state[CONST.MFA.FROM_LOGIN] || state[CONST.MFA.FROM_INVITE]) {
                    return analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_MFA_SETUP,
                      AnalyticsWrappedComponents.ConfigureMFA,
                      props
                    )
                  } else {
                    return (
                      <React.Fragment>
                        <UserSession.IfUserSessionExists>
                          {analyticsUtils.getAnalyticsWrapper(
                            SCREEN_NAMES.LOGIN_MFA_SETUP,
                            AnalyticsWrappedComponents.ConfigureMFA,
                            props
                          )}
                        </UserSession.IfUserSessionExists>
                        <UserSession.IfUserSessionAbsent>
                          <Redirect to={{ pathname: CONST.LOGIN_PATHNAME, search, state: {} }} />
                        </UserSession.IfUserSessionAbsent>
                      </React.Fragment>
                    )
                  }
                }}
              />

              <Route
                exact
                path="/login/update-password"
                render={(props) => {
                  const {
                    location: { state = {}, search },
                    history
                  } = props
                  if (!state[CONST.MFA.FROM_LOGIN]) {
                    history.push({ pathname: CONST.LOGIN_PATHNAME, search, state: {} })
                    return null
                  } else {
                    return analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_UPDATE_PASSWORD,
                      AnalyticsWrappedComponents.UpdatePassword,
                      props
                    )
                  }
                }}
              />

              <Route
                exact
                path="/set-password/:hash"
                render={(props) => (
                  <Suspense fallback={<div>Loading...</div>}>
                    <UserSession.DoesSessionExists>
                      {analyticsUtils.getAnalyticsWrapper(
                        SCREEN_NAMES.LOGIN_SET_PASSWORD,
                        AnalyticsWrappedComponents.SetPassword,
                        props
                      )}
                    </UserSession.DoesSessionExists>
                  </Suspense>
                )}
              />

              <Route
                exact
                path="/forgot-password"
                render={(props) =>
                  analyticsUtils.getAnalyticsWrapper(
                    SCREEN_NAMES.LOGIN_FORGOT_PASSWORD,
                    AnalyticsWrappedComponents.ForgotPassword,
                    props
                  )
                }
              />

              <Route
                exact
                path="/magiclink/request"
                render={(props) =>
                  analyticsUtils.getAnalyticsWrapper(
                    SCREEN_NAMES.LOGIN_MAGIC_LINK_REQUEST,
                    AnalyticsWrappedComponents.RequestMagicLink,
                    props
                  )
                }
              />

              <Route
                exact
                path="/magiclink/:hash/login"
                render={(props) =>
                  analyticsUtils.getAnalyticsWrapper(
                    SCREEN_NAMES.LOGIN_MAGIC_LINK,
                    AnalyticsWrappedComponents.MagicLinkLogin,
                    props
                  )
                }
              />

              <Route
                exact
                path="/invite/:hash"
                render={(props) => (
                  <UserSession.DoesSessionExists>
                    {analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_ACTIVATE_USER,
                      AnalyticsWrappedComponents.ActivateUser,
                      props
                    )}
                  </UserSession.DoesSessionExists>
                )}
              />

              <Route
                exact
                path="/reset-password/:hash"
                render={(props) => (
                  <UserSession.DoesSessionExists>
                    {analyticsUtils.getAnalyticsWrapper(
                      SCREEN_NAMES.LOGIN_RESET_PASSWORD,
                      AnalyticsWrappedComponents.ResetPassword,
                      props
                    )}
                  </UserSession.DoesSessionExists>
                )}
              />

              <Route
                exact
                path="/unlock/:hash"
                render={(props) =>
                  analyticsUtils.getAnalyticsWrapper(
                    SCREEN_NAMES.LOGIN_UNLOCK,
                    AnalyticsWrappedComponents.Unlock,
                    props
                  )
                }
              />

              <Route exact path="/session-exists">
                {AnalyticsWrappedComponents.SessionExists}
              </Route>

              {/* Routes For Global Login UI Starts Here */}
              <Route exact path="/email-login">
                <Suspense fallback={<div>Loading...</div>}>
                  <EmailLogin />
                </Suspense>
              </Route>

              <Route exact path="/domain-login">
                <Suspense fallback={<div>Loading...</div>}>
                  <DomainLogin />
                </Suspense>
              </Route>

              <Route exact path="/forgot-domain">
                <Suspense fallback={<div>Loading...</div>}>
                  <ForgotDomain />
                </Suspense>
              </Route>

              {/* Routes For Global Login UI Ends Here */}

              {/* For unknown routes always redirect to `/` (dashboard)  */}
              <Route render={() => <PageNotFound />} />
            </Switch>
          </AppDataProvider>
        </TopErrorBoundary>
      </AppContainer>
    )
  }
}

export default withRouter(App)
