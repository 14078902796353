import React, { Component } from 'react'
import styled from 'react-emotion'
import PropTypes from 'prop-types'
import { cx, css } from 'emotion'
import { rgba } from 'polished'
import { Box } from './../Grid'
import Loader from '../Loader'

export const BUTTON_SIZE_VARIANTS = {
  LARGE: 'large',
  MINI: 'mini',
  NORMAL: 'normal'
}

const ButtonContainer = styled('button')`
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  margin: ${({ inline }) => (inline ? '0 5px' : '0')};
  padding: ${({ size }) => (size === BUTTON_SIZE_VARIANTS.MINI ? '4px' : '8px 16px')};
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  ${({
    theme: {
      typography,
      colors: {
        text,
        components: { button }
      }
    },
    _type: type,
    size
  }) => {
    const { hover, color, ...styles } = button[type]
    const getHeight = (size) => {
      if (size === BUTTON_SIZE_VARIANTS.NORMAL) return '34px'
      if (size === BUTTON_SIZE_VARIANTS.LARGE) return '45px'
      return 'auto'
    }
    return cx(
      css(styles),
      css(typography.body.secondary),
      css({
        boxShadow: type === 'link' ? 'none' : '0 1px 0 0 rgba(24,50,71,0.05)',
        color: color,
        height: getHeight(size),
        fontSize: size === BUTTON_SIZE_VARIANTS.MINI ? '12px' : '14px'
      }),
      css({
        backgroundImage: `linear-gradient(180deg, ${styles.backgroundGradient.from} 0%, ${styles.backgroundGradient.to} 100%)`
      })
    )
  }}

  ${(props) => {
    const {
      theme: {
        colors: {
          components: { button },
          pseudo
        },
        palette
      },
      _type: type,
      loading
    } = props

    const { hover, active, disabled } = button[type]
    return `
      &:not([disabled]) {
        &:hover {
          background-color: ${hover};
          background-image: none;
        }

        &:active {
          outline: 0;
          box-shadow: inset 0px 0px 4px 1px ${rgba(palette.e[900], 0.2)};
          background-color: ${active.backgroundColor};
          border: 1px solid ${active.borderColor};
        }
      }

      &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
      }

      &:focus:not(:active) {
        outline: none;
        border: 1px solid transparent;
        box-shadow: 0 0 0 2px ${pseudo.focus};
      }

      ${
        !loading
          ? `&[disabled] {
            -webkit-box-shadow: none;
            box-shadow: none;
            background-image: linear-gradient(180deg, ${disabled.backgroundGradient.from} 0%, ${disabled.backgroundGradient.to} 100%);
            border: 1px solid ${disabled.borderColor};
            color: ${disabled.color};
          }`
          : ''
      }`
  }};
`

const loaderStyles = css`
  width: 16px;
  display: inline-block;
`

class Button extends Component {
  static propTypes = {
    /**
     * Apply presentational style on button
     */
    type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link']),

    /**
     * Inline the button with other content
     * If inlined the button width expands to accommodate the text inside it
     * Else it expands to the width of the parent
     */
    inline: PropTypes.bool,
    disabled: PropTypes.bool,

    /**
     * a larger version of the button
     * a similar approach is taken for input input fields
     * consider to use this when there are very few fields
     * like login or reset password page
     */
    large: PropTypes.bool,

    /**
     * this sets the html type attribute on the button component
     * Like, <button type="submit">Submit</button>
     */
    htmlType: PropTypes.string,

    /**
     * The class name returned by the css function from emotion
     * We use it to compose a unique classname using `cx`
     * https://emotion.sh/docs/cx
     */

    overrideStyleClassName: PropTypes.string,
    /**
     * this shows an inline loader when set
     */
    loading: PropTypes.bool,
    /**
     * displays a mini button - takes precendence
     * over large prop
     */
    size: PropTypes.oneOf([
      BUTTON_SIZE_VARIANTS.LARGE,
      BUTTON_SIZE_VARIANTS.MINI,
      BUTTON_SIZE_VARIANTS.NORMAL
    ])
  }

  static defaultProps = {
    type: 'primary',
    disabled: false,
    size: BUTTON_SIZE_VARIANTS.NORMAL,
    overrideStyleClassName: '',
    loading: false
  }

  render() {
    const {
      inline,
      children,
      disabled,
      className,
      htmlType,
      type,
      onClick,
      size,
      overrideStyleClassName,
      loading,
      ...rest
    } = this.props
    return (
      <ButtonContainer
        inline={inline}
        _type={type}
        disabled={disabled}
        className={cx(className, overrideStyleClassName)}
        type={htmlType}
        onClick={onClick}
        size={size}
        loading={loading}
        {...rest}>
        {loading ? (
          <Box className={loaderStyles}>
            <Loader size="small" invert={type !== 'secondary'} />
          </Box>
        ) : (
          children
        )}
      </ButtonContainer>
    )
  }
}

export default Button
