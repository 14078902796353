import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '../Grid'
import styled, { cx } from 'react-emotion'
import delve from 'dlv'

import { Field } from 'formik'
import FieldLabel from './FieldLabel'
import TextInput from './TextInput'
import Icon from '../Icon'

import { styledWithRTL } from './../../utils/styles'

import InlineMessage from '../InlineMessage'
import { PrimaryText, Anchor } from '../Typography'
import { isUrlAbsolute } from '../../utils'

const AffixIconDimension = '28px'
export const BorderRadius = '4px'

export const TEXT_AREA_HEIGHT = '124px'
export const INPUT_HEIGHT = '32px'
export const INPUT_HEIGHT_LARGE = '48px'
const BORDER_RADIUS = '4px'

const StyledLabel = styled(PrimaryText)`
  ${({ theme, large }) => {
    return `
      background: ${theme.palette.s[50]};
      padding: ${large ? '12px 7px' : '3.5px 10px'};
      border-top-left-radius: ${BORDER_RADIUS};
      border-bottom-left-radius: ${BORDER_RADIUS};
      border-right: 1px solid ${theme.colors.border.primary};
    `
  }}
`

const StyledPrimaryText = styled(PrimaryText)`
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
`

const StyledAnchor = styled(Anchor)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const LeadingIcon = styled(Flex)`
  ${({ type, theme }) => {
    const conditionalStyles =
      type === 'label' || type === 'link'
        ? `
    align-items:end;
    width:20px;
    background:none;
    `
        : `
    align-items: center;
    width: ${AffixIconDimension};
    background: ${theme.colors.border.secondary};
    `

    return `
      ${conditionalStyles}
      justify-content: center;
      border-top-left-radius: ${BORDER_RADIUS};
      border-bottom-left-radius: ${BORDER_RADIUS};
     `
  }}
`

const getValidUrl = (url) => {
  return isUrlAbsolute(url) ? url : `https://${url}`
}

const LeadingText = ({ children, large }) => <StyledLabel large={large}>{children}</StyledLabel>

const TrailingIcon = (props) => {
  return (
    <Box
      width={AffixIconDimension}
      height="100%"
      css={{
        textAlign: 'center',
        padding: props.large ? '15px 0' : '7px 0',
        paddingRight: '10px'
      }}>
      {props.children}
    </Box>
  )
}

const TrailingText = ({ children, large }) => <StyledLabel large={large}>{children}</StyledLabel>

const ResetField = ({ name, large }) => {
  return (
    <TrailingIcon large={large}>
      <Field name={name}>
        {({ form: { setFieldValue } }) => (
          <div onClick={() => setFieldValue(name, '')}>
            <Icon name="close" />
          </div>
        )}
      </Field>
    </TrailingIcon>
  )
}

const getHeightStyleFn = (props) => {
  const { _type, large } = props

  if (_type === 'textarea') {
    return TEXT_AREA_HEIGHT
  } else {
    if (large) {
      return INPUT_HEIGHT_LARGE
    } else {
      return INPUT_HEIGHT
    }
  }
}

const getStyleFn = (props, cssName) => {
  const {
    theme: { typography },
    _type
  } = props
  const styleObject = typography.input[_type]

  if (styleObject) {
    return styleObject[cssName]
  } else {
    return typography.input.text[cssName]
  }
}

export const FreshworksStyledTextInput = styled('div')`
  ${(props) => {
    const { theme, _disabled: disabled, _type: type, showError, readOnly, large, focused } = props

    const { colors, palette, typography } = theme

    return `
      width: 100%;
      height: ${getHeightStyleFn(props)};
      box-sizing: border-box;
      border-radius: ${BorderRadius};
      border: 1px solid ${colors.border.primary};
      background: white;
      appearance: none;

      textarea {
        resize: none;
      }

      input, textarea {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: ${large ? '10px' : '6px 12px'};
        box-sizing: border-box;
        color: ${colors.text.primary};
        font-size: ${getStyleFn(props, 'fontSize')};
        font-weight:${getStyleFn(props, 'fontWeight')};
        line-height: ${getStyleFn(props, 'lineHeight')};
        border: none;
        background: transparent;
        outline: none;

        &:focus {
          border-bottom: none;
        }
      }

      ${
        focused
          ? !showError
            ? `
              border: none;
              box-shadow: 0 0 0 2px ${colors.pseudo.focus};
              transition: box-shadow 0.3s ease-in-out;
            `
            : ''
          : `
            &:hover {
              border-color: ${colors.border.hover};
              transition: border-color 0.3s ease-in-out;
            }
          `
      }

      ${
        showError
          ? `
            box-shadow: 'none';
            border-color: ${colors.border.error};
            transition: border-color 0.3s ease-in-out;
            &:hover {
              border-color: ${colors.border.error};
            }
          `
          : ''
      }

      ${
        disabled || readOnly
          ? `
          cursor: not-allowed;
          pointer-events: none;
          border-color: ${colors.border.disabled};
          color: red;


          input[disabled], input[readonly] {
            font-weight: ${typography.input.disabled.fontWeight};
            background-color: ${palette.s[10]};
            color: ${colors.text.disabled};
            border-radius: ${BorderRadius};
          }
        `
          : ''
      }

      ${
        type === 'password'
          ? `
          input {
            letter-spacing: 5px;
            font-size: 20px;
          }
        `
          : ''
      }

      input::placeholder {
        color: ${colors.text.placeholder};
        font-weight: normal;
      }
    `
  }};
  ${({ overrideClass }) => cx(overrideClass)}
`

FreshworksStyledTextInput.displayName = 'Styled(TextInput)'

const FormikFieldContainer = styledWithRTL('div', {
  label: 'FormikFieldContainer'
})`
  position: relative;
`

const LabelOrLink = ({ type, name, labelText }) => {
  const LabelComponent = ({ name, labelText }) => (
    <StyledPrimaryText name={name} title={labelText} textWeight="bold">
      {labelText}
    </StyledPrimaryText>
  )

  if (labelText === '-') {
    return <LabelComponent name={name} labelText={labelText} />
  }

  return (
    <Fragment>
      {type === 'label' && <LabelComponent name={name} labelText={labelText} />}
      {type === 'link' && (
        <StyledAnchor name={name} title={labelText} target="_blank" href={labelText}>
          {labelText}
        </StyledAnchor>
      )}
    </Fragment>
  )
}

const setTouchBoolean = (form, fieldName, bool) =>
  form.setTouched(
    {
      ...form.touched,
      [fieldName]: bool
    },
    false
  )
export default class FormikField extends Component {
  interval = null

  static propTypes = {
    /**
     * this is an unqiue identfier of the field inside the Formik form
     * this `name` will be associated with the `values` managed by Formik
     * this also helps to associate validation error with the associated field
     */
    name: PropTypes.string.isRequired,

    /**
     * label for the input field
     * labels are required by a11y
     * https://www.w3.org/WAI/tutorials/forms/labels/
     */
    label: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf([
      'text',
      'password',
      'email',
      'number',
      'tel',
      'textarea',
      'label',
      'link'
    ]),
    /**
     * Optional prop. Specify the height in case of textarea
     */
    overrideClass: PropTypes.string,
    placeholder: PropTypes.string,
    /**
     * Specify the minimum number of characters to be entered
     */
    minLength: PropTypes.number,
    /**
     * Specify the maximum number of characters to be entered
     */
    maxLength: PropTypes.number,
    /**
     *  input box supports two height
     *  usually the larger one is used if there are very few inputs
     *  like on a login screen where we have only two inputs
     */
    large: PropTypes.bool,

    /**
     * Any further attributes you want to add to the input field
     */
    inputAttrs: PropTypes.object,
    /**
     * Note: This prop is for visual representation
     * Use this prop to avoid margin on top and bottom of the formik field
     */
    standAlone: PropTypes.bool,
    /**
     * Gives the input ref as the first argument of the function
     */
    textInputInnerRef: PropTypes.func,
    /**
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#readonly
     */
    readOnly: PropTypes.bool,
    /**
     * To mark a field as required field
     */
    required: PropTypes.bool,
    /**
     * https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444
     * If this property is set to true then it will delay the blur event of the
     * input field
     */
    delayFormBlur: PropTypes.bool
  }

  static defaultProps = {
    disabled: false,
    type: 'text',
    overrideClass: '',
    inputAttrs: {},
    standAlone: false,
    textInputInnerRef: () => {},
    readOnly: false,
    required: false,
    delayFormBlur: false
  }

  static LeadingIcon = LeadingIcon
  static LeadingText = LeadingText
  static TrailingIcon = TrailingIcon
  static TrailingText = TrailingText
  static ResetField = ResetField

  textInputRef = React.createRef()

  componentDidMount() {
    const { textInputInnerRef } = this.props
    if (this.textInputRef && this.textInputRef.current) {
      textInputInnerRef(this.textInputRef.current)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.interval)
  }

  render() {
    const {
      children,
      type,
      name,
      label,
      disabled,
      placeholder,
      large,
      standAlone,
      readOnly,
      required,
      maxLength,
      minLength,
      overrideClass,
      delayFormBlur
    } = this.props
    let Leading = null
    let Trailing = null

    React.Children.forEach(children, function (child) {
      if (child.type === LeadingIcon || child.type === LeadingText) {
        Leading = React.cloneElement(child, { large, type })
      } else if (child.type === TrailingIcon || child.type === TrailingText) {
        Trailing = React.cloneElement(child, { large })
      } else if (child.type === ResetField) {
        Trailing = React.cloneElement(child, { name, large })
      }
    })

    return (
      <FormikFieldContainer>
        <FieldLabel label={label} name={name} required={required} />
        <Box m={1} />
        <Field name={name}>
          {({ form, field }) => {
            const isTouched = delve(form, `touched.${name}`)
            const error = delve(form, `errors.${name}`, '')
            const showError = !disabled && isTouched && error.length > 0

            let labelText =
              (type === 'link' ? field.value && getValidUrl(field.value) : field.value) || '-'

            const canShowInlineError =
              isTouched && error != ' ' && (error.length > 0 || React.isValidElement(error))
            return type === 'label' || type === 'link' ? (
              <Flex>
                {typeof Leading === 'object' ? Leading : <Leading />}
                <LabelOrLink type={type} labelText={labelText} name={name} />
              </Flex>
            ) : (
              <Fragment>
                <FreshworksStyledTextInput
                  _type={type}
                  _disabled={disabled}
                  readOnly={readOnly}
                  showError={showError}
                  large={large}
                  focused={form.status && form.status[`is${name}Focused`]}
                  overrideClass={overrideClass}
                  onFocus={() => {
                    form.setStatus({ ...form.status, [`is${name}Focused`]: true })
                  }}
                  onBlur={() => {
                    if (typeof field.value === 'string') {
                      /*This check is to avoid type error for trim(). Field type 'number' is the only
                      exception case but it is already taken care by HTML's number input itself.
                      NOTE: Future non-string Formik Field values trimming has to be handled separately*/
                      form.setFieldValue(field.name, field.value.trim())
                    }
                    const isTouched = form.touched[field.name]
                    if (form.status && form.status.preventInputBlur) {
                      !isTouched && setTouchBoolean(form, field.name, false)
                      form.setStatus({
                        ...form.status,
                        preventInputBlur: false
                      })
                      return
                    }
                    !isTouched && delayFormBlur && setTouchBoolean(form, field.name, false)
                    form.setStatus({
                      ...form.status,
                      [`is${name}Focused`]: false
                    })
                    if (delayFormBlur) {
                      this.interval = setTimeout(() => {
                        setTouchBoolean(form, field.name, true)
                      }, 200)
                    }
                  }}>
                  <Flex css={{ height: '100%' }}>
                    {typeof Leading === 'object' ? Leading : <Leading />}
                    <Box flex={1}>
                      <TextInput
                        ref={this.textInputRef}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        minLength={minLength}
                        required={required}
                        isErrorPresent={canShowInlineError}
                        inputAttrs={this.props.inputAttrs}
                      />
                    </Box>
                    {typeof Trailing === 'object' ? Trailing : <Trailing />}
                  </Flex>
                </FreshworksStyledTextInput>
                {canShowInlineError && (
                  <Box my={1}>
                    <InlineMessage level="error" id={`${name}-error_msg`}>
                      {error}
                    </InlineMessage>
                  </Box>
                )}
              </Fragment>
            )
          }}
        </Field>
        {!standAlone && <Box mb="20px" />}
      </FormikFieldContainer>
    )
  }
}

export { FormikFieldContainer }
