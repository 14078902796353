import React, { useContext } from 'react'
import delve from 'dlv'

import SessionExists from '../../pages/SessionExists/SessionExists'
import { AppStateContext, USER_SESSION_DOES_NOT_EXIST } from '../AppDataProvider'
import { getDataFromAppStateContext } from '../AppDataProvider/AppStateContext'

const IfUserSessionExists = ({ children }) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  if (delve(getCurrentUserDetails, 'data.user.id')) {
    return typeof children === 'function'
      ? children(delve(getCurrentUserDetails, 'data.user'))
      : children
  } else {
    return null
  }
}

const IfUserSessionAbsent = ({ children }) => {
  const getCurrentUserDetails = getDataFromAppStateContext(
    useContext(AppStateContext),
    'getCurrentUserDetails',
    {}
  )
  if (
    (getCurrentUserDetails && getCurrentUserDetails === USER_SESSION_DOES_NOT_EXIST) ||
    !!getCurrentUserDetails.error
  ) {
    return children
  } else {
    return null
  }
}

const DoesSessionExists = ({ children }) => {
  return (
    <React.Fragment>
      <UserSession.IfUserSessionExists>
        {(user) => <SessionExists userEmail={user.email} />}
      </UserSession.IfUserSessionExists>
      <UserSession.IfUserSessionAbsent>{children}</UserSession.IfUserSessionAbsent>
    </React.Fragment>
  )
}

const UserSession = ({ children }) => {
  const appState = useContext(AppStateContext)
  const getCurrentUserDetails = getDataFromAppStateContext(appState, 'getCurrentUserDetails', {})
  if (delve(getCurrentUserDetails, 'data.user.id')) {
    return children(getCurrentUserDetails.data)
  } else {
    return null
  }
}
UserSession.IfUserSessionExists = IfUserSessionExists
UserSession.IfUserSessionAbsent = IfUserSessionAbsent
UserSession.DoesSessionExists = DoesSessionExists

export default UserSession
