//import './wdyr'

import React, { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import './globalCSS'

import App from './App'
import FreshworksTheme from '../src/styles/themes/freshworks'
import AppWrapper from './AppWrapper'
import { useMocks } from './utils'
import { applyMock } from './components/DataManager'
import { ENVIRONMENT } from './config'
import { isStorageAvailable, CONST } from './utils'

const applyMocks = () => {
  if (process.env.NODE_ENV === 'development') {
    return new Promise(function (resolve) {
      useMocks().then((mocks) => {
        const mockInstance = applyMock()
        for (let mock of mocks) {
          mock.default(mockInstance)
        }
        resolve()
      })
    })
  } else {
    return Promise.resolve()
  }
}

applyMocks().then(() => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(
    <Fragment>
      <AppWrapper theme={FreshworksTheme}>
        <App />
      </AppWrapper>

      {ENVIRONMENT !== 'production' &&
        isStorageAvailable(CONST.WEB_STORAGE.LOCAL_STORAGE) &&
        localStorage.getItem('crowdinInContext') === 'true' && (
          <Helmet>
            <script type="text/javascript">
              var _jipt = []; _jipt.push(['project', 'freshid-new-project']);
            </script>
            <script type="text/javascript" src="//cdn.crowdin.com/jipt/jipt.js" />
          </Helmet>
        )}
    </Fragment>,
    document.getElementById('root')
  )
})

/**
 * TODO: verify and confirm serviceWorkers' ability
 * to work with CDN assets, side effects of caching,
 * backward compatibility with backend and then enable
 */
//registerServiceWorker()
